 /* @import url('http://fonts.googleapis.com/css?family=Open+Sans'); */

/* *{
    font-family: 'Montserrat', sans-serif;
} */



.RegistrationCardContainer {
    /* background-color: rgb(242, 246, 252); */
    background-image: url(https://aicee.in/spot/h_assets/img/dummy/bg11.jpg);
    background-size: cover;
    
    /* height: 100vh; */
    
}
.LandingPara{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-size: 14px;
}
li{
    list-style: disc;
}

.RegistrationCard {
    padding: 1.5rem 8rem;

}

.RegistrationCard2 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 1.2rem;
    /* background-color: rgb(243, 79, 14); */
    
}

.RegistrationCard3 {
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 10px 10px 0 0;
    
    /* background-color: rgb(243, 79, 14); */
}
.RegistrationCard50 {
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    flex-direction: row;
    /* border-radius: 10px 10px 0 0; */
    
    /* background-color: rgb(243, 79, 14); */
}

.RegistrationCard4 {
    /* border: 1px solid black; */
    width: 15%;
    margin: auto;

}

.RegistrationCard4>img {
    /* border: 1px solid black; */

    width: 4rem;
    margin: auto;
    align-items: center;

}
@media only screen and (max-width: 600px){
    .RegistrationCard4>img {
        /* border: 1px solid black; */
    
        width: 4rem;
        margin: auto;
        align-items: center;
        margin-top: 5rem;
    
    }
    
}

.RegistrationCard5 {
   
    width: 85%;
    margin: auto;

}

.RegistrationCard5>p {
    color: white;
    font-size: 1.6rem;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-weight: bold;
    /* color: white; */
    margin-top: 1rem;
    text-align: center;
    margin-right: 7rem;

}


.RegistrationCard6 {
    /* border: 1px solid black; */
    margin: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 7px;
    padding-bottom: 1rem;
}

.RegistrationCard7 {
    /* border: 1px solid black; */
    margin: 1rem;
    /* width: 100%; */

}
.RegistrationCard7 > img{
    width: 100%;
    height: 80%;
    border-radius: 10% 10%;
    overflow: hidden;
    /* border: 1px solid black; */
}

.RegistrationLogin {
    width: 100%;
    background-color: #383CC1;
    color: white;
    border-radius: 8px 8px 0 0;
    font-size: 1.1rem;
    padding: 0.7rem;
}

.RegistrationLoginDetails {
    margin: 1rem;
    text-align: start;
}

.RegistrationUsername {
    display: flex;
    color: rgb(51, 122, 183);
}

.icon {
    border: 1px solid rgb(182, 182, 182);
    font-size: 1rem;
    padding: 4px 15px;
}

.RegistrationUsername>div>input {
    padding: 4px 10px;
    width: 150%;
    outline: none;
}

.registrationButton {
    margin-top: 1.2rem;
}

.registrationButton1 {
    background-color: rgb(51, 122, 183);
}

.registrationButton3 {
    background-color: rgb(247, 47, 40);

}

.applicationStages{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.applicationStages2{
    padding: 0 0 0 0;
}
.otpCard{
    margin: 2rem 15rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dashboard1{
    margin-left: -14rem;
}


@media only screen and (max-width: 600px) {
   
    .RegistrationCard {
        padding: 0;
    
    }
    .RegistrationCard3 {
        /* border: 1px solid black; */
        width: 100%;
        display: grid;
        /* flex-direction: row; */
        border-radius: 10px 10px 0 0;
        
        /* background-color: rgb(243, 79, 14); */
    }
    .RegistrationCard4 {
        width: 100%;
        margin-top: 2rem;
    }
    .registrationStep{
        margin-top: -4rem;
    }
    .RegistrationCard4>img {

        width: 8rem;
        margin: auto;
    
    }
    .RegistrationCard5 {
        color: white;
        width: 100%;
    
    }
    .RegistrationCard5>p{
   
        margin-right: 0;
    
    }
    .applicationStages{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
    .otpCard{
        margin: 2rem 2rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
   

}
@media only screen and (max-width: 1100px) {
   
    .RegistrationCard {
        padding: 0;
    
    }
    .col_step_container{
        border: 5px solid black;
        display: flex;
        flex-direction: column;
    }
    .coll_step{
        width: 100%;
    }
    .col_step_container>span{
        display: none;
    }
    .otpCard{
        margin: 2rem 1rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    
    
    
    

}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}


.col_step_container{
    /* border: 1px solid black; */
    display: flex;
    /* grid-template-columns: repeat(6,1fr); */
    flex-direction: row;
    grid-gap: 1rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: -4rem;
    padding: 1.5rem;
}
.coll_step{
    width: 100%;
    border: 1px solid rgb(172, 172, 172);
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: #241d75;
    border-radius: 10%;

}
.coll_step>span{
    /* border: 1px solid gray; */
    text-align: center;
    font-size: 2.2rem;
    border-radius: 50%;
    padding: 0.7rem;
    /* margin-top: 10rem; */
}
.coll_step>p{
    /* border: 1px solid gray; */
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 1rem;
}
.col_step_container>span{
    font-size: 2rem;
    margin-top: 5%;
    color: #241d75;
}
.accordion-body{
    padding:0;
    color:'red'
}



/* ***************************************** */

.accordion__section {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    border-radius: 1rem;
  }
  
  /* Style the buttons that are used to open and close the accordion panel */
  .aaccordion {
    background-color: #eee;
    color: #04124f;
    cursor: pointer;
    padding: 0.3rem 2rem 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    font-family: 'Open Sans', sans-serif;
    border-radius: 0.3rem;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  /* .accordion:hover,
  .active {
    background-color: #ccc;
  } */
  
  /* Style the accordion content title */
  .accordion__title {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4em;
  }
  
  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }
  
  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }
  
  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
  }
  
  /* Style the accordion content text */
  .accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }


  .landingImage{
    background-image: url(https://images.unsplash.com/photo-1580086319619-3ed498161c77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80);
    /* background-size: 100vh; */
    background-size: cover;
  }
