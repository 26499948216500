.spotheader{
  background-color: #042e2e;
}
.spotSidebar{
  background-color: #042e2e;
  color: white;
}
.sidebarHeadings{
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
  