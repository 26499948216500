/* *{
    font-family: Montserrat, sans-serif;
    letter-spacing: 0.5px;
} */
.counsellingTable{
  /* display: none; */
}
@media only screen and (max-width: 600px){
  .counsellingTableParent{
    border: 2px solid black;
    width: 100%;
  }
  .counsellingTable2{
    width: 10rem;
  }
  
}
.LoginPannelContainer{
    /* display: grid; */
    /* grid-template-columns: repeat(2,1fr); */
    margin: auto;
    /* text-align: center; */
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    /* color: rgb(240, 114, 30); */
    
    /* border: 1px solid black; */
    /* background-color: rgb(204,255,204); */
    /* width: 80%; */
    /* height: 100vh; */
  }
  .LoginPannel{
    /* border: 1px solid red; */
    background-color: white;
    
    
  }
  .pdfFile{
   /* border: 1px solid black; */
   margin-left: 20%;
  }
  .payment-form>form{
    border: none;
  }
  .InformationPannel{
    /* border: 1px solid yellow; */
    background-color: white;
    /* width: 70%; */
  }
  .aicee-result{
    width:50%;
    margin: auto;
  }
  .collegeSearch{
    display: block;
    text-align: center;
    justify-content: space-around;
    width: 70%;
    margin: auto;
    color: rgb(234, 190, 149);
    
  }
  .studentDashboardContainer{
    /* background-color: rgb(204,255,204); */
    font-weight: 500
  }
  .studentDashboard-nav{
    width: 85%;
    margin: auto;
    padding: 1rem;
    /* border: 1px solid black; */
    display: flex;
    /* grid-template-columns: repeat(2,1fr); */
    justify-content: space-between;
    color: white;
    border-radius: 7px;
    list-style: none;
    background-color: rgb(1,32,96);
  }
  .studentDashboard-nav>div>a{
    text-decoration: none;
    color: white;
  }
  .studentDashboard-candiateSection{
    width: 85%;
    margin: auto;
    /* border: 1px solid red; */
    /* display: grid;
    grid-template-columns: repeat(2,1fr); */
  }
  .rankCard{
    width: 85%;
    margin: auto;
    border: 1px solid rgb(199, 196, 196);
    border-radius: 7px;
    background-color: white;
    padding: 1rem 0;
    /* display: grid;
    grid-template-columns: repeat(2,1fr); */
  }
  .rank-card-details>div>div{
    width: 97%;
    margin: auto;
    border: 1px solid rgb(199, 196, 196);
  }
  .rank-profile{
    /* border: 1px solid black; */
    width:70%;
    margin: 1rem 30% 1rem 30%;
    /* margin-left: 3rem; */
  }
  .rank-profile>span{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  .studentDashboard-left{
    border: 1px solid rgb(1,32,96);
    height: 70vh;
    background-color: rgb(1,32,96);
    border-radius: 5px;
    color: rgb(228, 228, 228);
  }
  .studentDashboard-left>div>li>a{
    color:rgb(194, 194, 194);
    /* font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
    font-weight: 500;
  
  }
  .studentDashboard-left>div>li{
    /* border: 1px solid red; */
    width: 95%;
    padding: 5px;
    font-size: 1.1rem;
  }
  .studentDashboard-left>div>li:hover{
      background-color: rgb(23, 52, 110);
      border-radius: 4px;
  }
  .studentDashboard-left>div>li>a:hover{
    color: white;
  }
  .dashboard-student{
    color:rgb(212, 207, 207)
  }
  .dashboard-student:hover{
    color:white
  }
  .studentDashboard-right{
    border: 1px solid rgb(1,32,96);
    height: 70vh;
    background-color: white;
    border-radius: 5px;
  
  }
  .documentUpload{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(1,1fr);
    /* border: 1px solid black; */
    background-color: rgb(244,246,249);
    padding: 2rem 3rem;
  }
  .documentUpload-div{
    display: flex;
    justify-content: space-between;
    /* border: 1px solid black; */
  }
  .uploadAndNext{
    
    padding: 0.5rem 2rem;
  }
  .uploadAndNext>div>center>button{
    background-color: rgb(1,32,96);
    color: white;
    font-weight: 500;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-align: center;
  }
  .paymentButton{
    background-color:#042e2e;
    color: white;
    font-weight: 500;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-align: center;
  }
  .uploadButton{
    background-color: rgb(1,32,96);
    color: white;
    border: none;
    border-radius: 5px;
    text-align: center;
  }
  .selectedCollege{
    background-color: rgb(1,32,96);
    padding: 0.3rem 0.7rem;
    color: white;
    margin-top: 5rem;
    border-radius: 5px 5px 0 0;
  }
  .selectedCollege:hover{
    color: white;
    background-color: rgb(23, 52, 110);
  }
  .rankCardDiv{
    /* border: 1px solid black; */
    width: 60%;
    margin: auto;
    text-align: start;
  }
  .rankCardDiv>span{
   display: flex;
   justify-content: space-between;
   /* align-items: start; */
   text-align: start;
  }

  button.print-button {
    width: 100px;
    height: 100px;
  }
  span.print-icon, span.print-icon::before, span.print-icon::after, button.print-button:hover .print-icon::after {
    border: solid 4px #333;
  }
  span.print-icon::after {
    border-width: 2px;
  }
  
  button.print-button {
    position: relative;
    padding: 0;
    border: 0;
    
    border: none;
    background: transparent;
  }
  
  span.print-icon, span.print-icon::before, span.print-icon::after, button.print-button:hover .print-icon::after {
    box-sizing: border-box;
    background-color: #fff;
  }
  
  span.print-icon {
    position: relative;
    display: inline-block;  
    padding: 0;
    margin-top: 20%;
  
    width: 50%;
    height: 35%;
    background: #fff;
    border-radius: 20% 20% 0 0;
  }
  
  span.print-icon::before {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 12%;
    right: 12%;
    height: 90%;
  
    transition: height .2s .15s;
  }
  
  span.print-icon::after {
    content: " ";
    position: absolute;
    top: 55%;
    left: 12%;
    right: 12%;
    height: 0%;
    background: #fff;
    background-repeat: no-repeat;
    background-size: 70% 90%;
    background-position: center;
    background-image: linear-gradient(
      to top,
      #fff 0, #fff 14%,
      #333 14%, #333 28%,
      #fff 28%, #fff 42%,
      #333 42%, #333 56%,
      #fff 56%, #fff 70%,
      #333 70%, #333 84%,
      #fff 84%, #fff 100%
    );
  
    transition: height .2s, border-width 0s .2s, width 0s .2s;
  }
  
  button.print-button:hover {
    cursor: pointer;
  }
  
  button.print-button:hover .print-icon::before {
    height:0px;
    transition: height .2s;
  }
  button.print-button:hover .print-icon::after {
    height:120%;
    transition: height .2s .15s, border-width 0s .16s;
  }
  .allotedStudent{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }

  .allotedStudent>div{
    margin-top: 2rem;
    /* border: 1px solid black; */
  }
  .allotedStudent>div>center>img{
    width: 70%;
    height: 50%;
    /* border: 1px solid black; */
    border-radius: 50%;
    margin: auto;
  }
  @media only screen and (max-width:575px){
    .loginImage{
      width: 80%;
    }
    .allotedStudent{
      display: grid;
      grid-template-columns: repeat(1,1fr);
    }
  }
  @media only screen and (max-width: 1200px){
    .LoginPannelContainer{
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .LoginPannel{
      width:100%
    }
    .InformationPannel{
      width: 100%;
    }
    .aicee-result{
      width:100%
    }
    .collegeSearch{
      display: grid;
      grid-template-columns: repeat(1,1fr);
      gap: 1rem;
      justify-content: space-around;
     text-align: center;
      margin: auto;
    }
    .studentDashboard-candiateSection{
      display: grid;
      margin: auto;
    }
    .studentDashboard-left{
      width: 90%;
      margin: auto;
    }
    .studentDashboard-right{
      width: 90%;
      margin: auto;
      margin-top: 2rem;
    }
    .rank-profile{
      /* border: 1px solid black; */
      width:90%;
      
      /* margin-left: 3rem; */
    }
    .rank-profile>span{
      display: flex;
      /* grid-template-columns: repeat(2,1fr); */
      justify-content: space-between;
    }
    .rankCardDiv{
      /* border: 1px solid black; */
      width: 100%;
      margin: auto;
      text-align: start;
  
    }
  }


            /* ADMIN LOGIN  */

            .gradient-custom {
              /* fallback for old browsers */
              background: #6a11cb;
              
              /* Chrome 10-25, Safari 5.1-6 */
              background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
              
              /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
              background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
              }


              /* //////////////  ALLOTMENT LETTER CSS   //////////////////////// */
              .txt-center {
                text-align: center;
            }
            .border- {
                border: 1px solid #000 !important;
            }
            .padding {
                padding: 15px;
            }
            .mar-bot {
                margin-bottom: 15px;
            }
            .admit-card {
                border: 2px solid #000;
                padding: 15px;
                margin: 20px 0;
            }
            .BoxA h5, .BoxA p {
                margin: 0;
            }
            .text-center>h5 {
                text-transform: uppercase;
            }
            /* .allotment-img>img {
                width: 100%;
                margin: 0 auto;
            } */
            .table-bordered td, .table-bordered th, .table thead th {
                border: 1px solid #000000 !important;
            }
            












            /* //   SPOT CSS /// */

            .sidebar {
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              min-height: 100vh !important;
              z-index: 100;
              padding: 48px 0 0;
              box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
          }
          #sidebar-wrapper{
              min-height: 100vh !important;
              width: 100vw;
              margin-left: -1rem;
              -webkit-transition: margin .25s ease-out;
              -moz-transition: margin .25s ease-out;
              -o-transition: margin .25s ease-out;
              transition: margin .25s ease-out;
          }
          #sidebar-wrapper .sidebar-heading {
              padding: 0.875rem 1.25rem;
              font-size: 1.2rem;
          }
          
          #page-content-wrapper {
              min-width: 0;
              width: 100%;
          }

          /* .custom-dialog { width: 150%; } */